<template>
  <div class="happy-bubbles">
    <div
      class="happy-bubbles__success-bubble"
      :id="`${id}_bubble_${index}`"
      v-for="(bubble, index) in bubbles"
      :key="index"
    >
      <SvgSprite
        :svgSrc="Face"
        :id="`${id}_bubble_svg_${index}`"
        :sprite="
          ['Base', 'Grin', 'Pop1', 'Pop2', 'Empty'][Math.floor(bubble.frame)]
        "
        class="success-bubble__face"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap"
import { Cubic, MotionPathPlugin } from "gsap/all"
gsap.registerPlugin(Cubic, MotionPathPlugin)

import Face from "@/assets/sprites/face_test.svg?external"

export default {
  props: {
    active: Boolean
  },

  data: () => ({
    id: null,
    Face,
    bubbles: []
  }),

  created() {
    this.id = Math.random()
  },

  mounted() {
    this.attachBubbles()
  },

  methods: {
    attachBubbles() {
      let _tempArr = []
      for (let i = 0; i < 3; i++) {
        _tempArr.push({
          el: `${this.id}_bubble_${i}`,
          svg: `${this.id}_bubble_svg_${i}`,
          frame: 1
        })
      }
      this.bubbles = [..._tempArr]
    },

    animateBubbles() {
      let randomPath = () => {
        let randomBetween = (min, max) => Math.random() * (max - min) + min
        let pathArr = []
        // Array of 4 coordinates to create a cubic bezier path
        for (let i = 0; i < 4; i++) {
          pathArr.push({
            x: randomBetween(-30, 30),
            y: i * randomBetween(-40, -50)
          })
        }
        return pathArr
      }

      this.bubbles.forEach((bubble, i) => {
        // Animate the sprite frames
        gsap.to(bubble, {
          duration: "random(2,3)",
          delay: i * 0.1,
          frame: 4,
          ease: Cubic.easeIn
        })
        // Float up
        gsap.to(document.getElementById(bubble.el), {
          duration: "random(2, 4)",
          scale: 1.1,
          transformOrigin: "center",
          motionPath: {
            path: randomPath(),
            type: "cubic",
            curviness: 2,
            autoRotate: false
          }
        })
      })
    }
  },

  watch: {
    active: {
      handler: function(newVal) {
        if (newVal) {
          const unWatch = this.$watch(
            "bubbles",
            newVal => {
              if (newVal && newVal.length > 0) {
                this.$nextTick(() => {
                  this.animateBubbles()
                })
                unWatch()
              }
            },
            { immediate: true }
          )
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.happy-bubbles {
  pointer-events: none;

  &__success-bubble {
    position: absolute;
    width: 22px;
    height: 22px;
    transform: translateX(-50%) rotate(180deg);
    top: 100%;

    svg {
      path {
        stroke: getcolour(bleach_white);
        fill: getcolour(bleach_white);
      }
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        position: absolute;
        left: calc((40% * #{$i}) - 30%);
      }
    }
  }
}
</style>
